<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <v-data-table
          :headers="headers"
          :items="users"
          :search="search"
          class="elevation-1 mt-4"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
import RestResource from "../../../services/dataServiceWebsite";
const service = new RestResource();

export default {
  data() {
    return {
      users: [],
      headers: [
        { text: "User Id", value: "_id" },
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Phone Number", value: "phoneNumber" },
        { text: "Country", value: "country" },
        { text: "DOB", value: "dateOfBirth" },
        { text: "Referral Code", value: "referralCode" },
      ],
      search: "",
    };
  },

  mounted() {
    this.getUsers();
  },

  methods: {
    getUsers() {
      this.$setLoader();
      service.getCommunityUsers({ data: true }).then((r) => {
        this.users = r.data.users.map((user) => {
          return {
            ...user,
            dateOfBirth: moment(user.dateOfBirth).format("YYYY-MM-DD"),
          };
        });
      });
      this.$disableLoader();
    },
  },
};
</script>
