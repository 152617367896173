import axios from 'axios'
import Util from "../../Util.js"

var ADMIN_BACKEND_URL = process.env.VUE_APP_ADMIN_BACKEND_URL

export default class RestResource {
    async getEnquiry(params) {
        const url = `${ADMIN_BACKEND_URL}/website/enquiry/get`
        return await axios.post(url, params, Util.setupHeaders())
    }
    async getCommunityUsers(params) {
        const url = `${ADMIN_BACKEND_URL}/website/community/user/get`
        return await axios.post(url, params, Util.setupHeaders())
    }
}
